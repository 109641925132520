import { useEffect, useRef } from "react";

import logo from "../../../../assets/logo.png";
import bIcon1 from "../../../../assets/bIcon1.png";
import bIcon2 from "../../../../assets/bIcon2.png";
import bIcon3 from "../../../../assets/bIcon3.png";
import * as THREE from 'three';
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default function CanvasPart() {
  const canvasRef = useRef();
  const modelRef = useRef([]);
  const sceneRef = useRef();
  const cameraRef = useRef();
  const orbitRef = useRef();
  const rendererRef = useRef();
  const firstLoad = useRef(true);



  const logCameraPosition = () => {
    if (cameraRef.current) {
      const { x, y, z } = cameraRef.current.position;
      console.log(`Camera Position - x: ${x}, y: ${y}, z: ${z}`);
    }
  };

  const init = () => {
    // Create scene
    const scene = new THREE.Scene();
    // scene.background = new THREE.Color(0xf0f0f0);
    sceneRef.current = scene;

    // Set up camera
    const camera = new THREE.PerspectiveCamera(45, canvasRef.current.clientWidth / canvasRef.current.clientHeight, 0.001, 1000);
    // camera.position.set(-0.242, 0.284, 0.2395);
    camera.position.set(-0.19637791129350296,0.1334634246880408,0.04490192546956725);
    // camera.lookAt(0, 0, 0)
    cameraRef.current = camera;

    // Set up renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setClearColor(0x000000, 0);  // Make the background transparent
    renderer.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);

    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.7;

    rendererRef.current = renderer;
    canvasRef.current.appendChild(renderer.domElement);

    // Set up OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = true;
    orbitRef.current = controls;
    controls.addEventListener('change', logCameraPosition);


    const size = 10;
    const divisions = 10;

    const gridHelper = new THREE.GridHelper(size, divisions);
    // sceneRef.current.add(gridHelper);

    // Set up environment
    const environment = new RoomEnvironment();
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    sceneRef.current.environment = pmremGenerator.fromScene(environment).texture;

    // Load the GLTF model
    const loader = new GLTFLoader();
    loader.load("/model/flower2.glb", (gltf) => {
      const model = gltf.scene;
      model.position.set(0, 0, 0)
      sceneRef.current.add(model);
      modelRef.current = model;
    });

    // Lighting
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(5, 5, 5);
    sceneRef.current.add(light);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    sceneRef.current.add(ambientLight);

    // Animate function
    const animate = () => {
      requestAnimationFrame(animate);
      orbitRef.current.update();
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      cameraRef.current.aspect = canvasRef.current.clientWidth / canvasRef.current.clientHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (rendererRef.current) rendererRef.current.dispose();
    };
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      init();  // Call init function to set up Three.js
    }
  }, []);

  const handleReset = () => {
    alert("Reset");
  }

  const handleZoom = () => {
    alert("zomm");
  }

  return (
    <>
      <div className="CanvasPart">
        <div className="Logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="Canvas" ref={canvasRef}>

        </div>

        <div className="CanvasPart-controler">
          <button
            className="btn CanvasPart-controler-item"
            onClick={handleReset}
          >
            <img src={bIcon1} alt="reset" width="13px" height="auto" />
          </button>
          <button className="btn CanvasPart-controler-item">
            <img
              src={bIcon2}
              alt="aommOut"
              width="13px"
              height="auto"
              onClick={handleZoom}
            />
          </button>
          <button className="btn CanvasPart-controler-item">
            <img
              src={bIcon3}
              alt="zoomIn"
              width="13px"
              height="auto"
              onClick={handleZoom}
            />
          </button>
        </div>
      </div>
    </>
  );
}
