// mobile-size

import { useContext, useState } from "react";
import icon9 from "../.../../../../../../../../assets/icon9.png";
import { TextContext } from "../TextContext/TextContextProvider";

export default function FontOffcanvas() {
  
  const {font, setFont} = useContext(TextContext);

  return (
    <>
      <div className="FontOffcanvas">
        <button
          class="btn FontOffcanvas-button"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasBottom"
          aria-controls="offcanvasBottom"
        >
          {font}
          <img src={icon9} alt="icon9" />
        </button>

        <div
          class="offcanvas offcanvas-bottom FontOffcanvas-content"
          tabindex="-1"
          id="offcanvasBottom"
          aria-labelledby="offcanvasBottomLabel"
        >
          <div class="offcanvas-header FontOffcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasBottomLabel">
              Font Type
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body small FontOffcanvas-body">
            <div
              className="FontOffcanvas-body-item"
              style={{ borderTop: "0.5px solid #9C9C9C" }}
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              font1
            </div>
            <div
              className="FontOffcanvas-body-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              font2
            </div>
            <div
              className="FontOffcanvas-body-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              font3
            </div>
            <div
              className="FontOffcanvas-body-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              font4
            </div>
            <div
              className="FontOffcanvas-body-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              font5
            </div>
            <div
              className="FontOffcanvas-body-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              font6
            </div>
            <div
              className="FontOffcanvas-body-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              font7
            </div>
            <div
              className="FontOffcanvas-body-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              font8
            </div>
            <div
              className="FontOffcanvas-body-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              font9
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
