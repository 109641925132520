import { useContext, useState } from "react";
import rose3 from "../../../../assets/rose3.png";
import rose4 from "../../../../assets/rose4.png";
import TextPanel from "./TextPanel/TextPanel";
import Material from "./Material/Material";
import Price from "./Price/Price";
import { HomeContext } from "../../Context/HomeContext";
import Boxes from "./Boxes/Boxes";
import Quabtity from "./Quantity/Quantity";

export default function DesignPanel() {
  const [roseColor, setRoseColor] = useState("DF818A");
  const { menu, setMenu } = useContext(HomeContext);
  const [roseType, setRoseType] = useState("Short-Stem-Rose");

  return (
    <>
      <div className="DesignPanel-container col-lg-9 p-0">
        <section className="DesignPanel-tools" id="accordionEx">
          <h3 className="DesignPanel-tools-title">Design Your Own</h3>
          <div className="DesignPanel-tools-typeRose">
            <div className="DesignPanel-tools-typeRose-container">
              <div
                className="DesignPanel-tools-typeRose-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#ChooseType"
                aria-expanded="false"
                aria-controls="ChooseType"
                onClick={() => {
                  if (menu !== "typeRose") {
                    setMenu("typeRose");
                  } else {
                    setMenu(null);
                  }
                }}
              >
                <span style={{
                      fontWeight : menu === "typeRose" ? "700" : "400"
                    }}>Choose Type</span>
                <i
                  class="bi bi-chevron-up"
                  style={{
                    transform:
                      menu !== "typeRose" ? "rotate(0.5turn)" : "rotate(1turn)",
                  }}
                ></i>
              </div>
              <div
                className={`DesignPanel-tools-typeRose-content collapse ${
                  menu === "typeRose" ? "show" : "null"
                }`}
                id="ChooseType"
                data-bs-parent="#accordionEx"
              >
                <section className="Rose-type ">
                  <div
                    className="Rose-type-item"
                    onClick={() => {
                      setRoseType("Short-Stem-Rose");
                    }}
                  >
                    <div
                      className={`Rose-type-item-image ${
                        roseType === "Short-Stem-Rose" ? "ActiveRose" : ""
                      }`}
                    >
                      <img src={rose3} alt="rose3" />
                    </div>
                    <span className="Rose-type-item-text" style={{
                      fontWeight : menu === "typeRose" ? '600' : '400'
                    }}>Short Stem Rose</span>
                  </div>
                  <div
                    className="Rose-type-item"
                    onClick={() => {
                      setRoseType("Petal");
                    }}
                  >
                    <div
                      className={`Rose-type-item-image ${
                        roseType === "Petal" ? "ActiveRose" : ""
                      }`}
                    >
                      <img src={rose4} alt="rose4" />
                    </div>
                    <span className="Rose-type-item-text">Petal</span>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="DesignPanel-tools-colorRose">
            <div className="DesignPanel-tools-colorRose-container">
              <div
                className="DesignPanel-tools-colorRose-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#colorRose"
                aria-expanded="false"
                aria-controls="colorRose"
                onClick={() => {
                  if (menu !== "colorRose") {
                    setMenu("colorRose");
                  } else {
                    setMenu("typeRose");
                  }
                }}
              >
                <span style={{
                      fontWeight : menu === "colorRose" ? "700" : "400"
                    }}>Choose Color</span>
                <i
                  class="bi bi-chevron-up"
                  style={{
                    transform:
                      menu !== "colorRose"
                        ? "rotate(0.5turn)"
                        : "rotate(1turn)",
                  }}
                ></i>
              </div>
              <div
                className={`DesignPanel-tools-colorRose-content collapse ${
                  menu === "colorRose" ? "show" : "null"
                }`}
                id="colorRose"
                data-bs-parent="#accordionEx"
              >
                <section className="Rose-color">
                  <div
                    style={{
                      border: `${
                        roseColor === "DF818A" ? "2px" : "1px"
                      } solid ${
                        roseColor === "DF818A" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "DF818A") {
                        setRoseColor("DF818A");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#DF818A",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "FFE500" ? "2px" : "1px"
                      } solid ${
                        roseColor === "FFE500" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "FFE500") {
                        setRoseColor("FFE500");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#FFE500",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "11C3FC" ? "2px" : "1px"
                      } solid ${
                        roseColor === "11C3FC" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "11C3FC") {
                        setRoseColor("11C3FC");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#11C3FC",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "6B00BE" ? "2px" : "1px"
                      } solid ${
                        roseColor === "6B00BE" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "6B00BE") {
                        setRoseColor("6B00BE");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#6B00BE",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "FF0000" ? "2px" : "1px"
                      } solid ${
                        roseColor === "FF0000" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "FF0000") {
                        setRoseColor("FF0000");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#FF0000",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "FFF2F3" ? "2px" : "1px"
                      } solid ${
                        roseColor === "FFF2F3" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "FFF2F3") {
                        setRoseColor("FFF2F3");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#FFF2F3",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "D159E4" ? "2px" : "1px"
                      } solid ${
                        roseColor === "D159E4" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "D159E4") {
                        setRoseColor("D159E4");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#D159E4",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "14FF00" ? "2px" : "1px"
                      } solid ${
                        roseColor === "14FF00" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "14FF00") {
                        setRoseColor("14FF00");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#14FF00",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "6FB891" ? "2px" : "1px"
                      } solid ${
                        roseColor === "6FB891" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "6FB891") {
                        setRoseColor("6FB891");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#6FB891",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "FF7A00" ? "2px" : "1px"
                      } solid ${
                        roseColor === "FF7A00" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "FF7A00") {
                        setRoseColor("FF7A00");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#FF7A00",
                      }}
                    ></p>
                  </div>
                  <div
                    style={{
                      border: `${
                        roseColor === "FF00A8" ? "2px" : "1px"
                      } solid ${
                        roseColor === "FF00A8" ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => {
                      if (roseColor !== "FF00A8") {
                        setRoseColor("FF00A8");
                      }
                    }}
                  >
                    <p
                      style={{
                        backgroundColor: "#FF00A8",
                      }}
                    ></p>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="DesignPanel-tools-designRose">
            <div
              className="DesignPanel-tools-designRose-container"
              id="accordionDesignRose"
            >
              <div
                className="DesignPanel-tools-designRose-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#designRose"
                aria-expanded="false"
                aria-controls="designRose"
                onClick={() => {
                  if (menu !== "designRose") {
                    setMenu("designRose");
                  } else {
                    setMenu("typeRose");
                  }
                }}
              >
                <span style={{
                      fontWeight : menu === "designRose" ? "700" : "400"
                    }}>Design Your Rose</span>
                <i
                  class="bi bi-chevron-up"
                  style={{
                    transform:
                      menu !== "designRose"
                        ? "rotate(0.5turn)"
                        : "rotate(1turn)",
                  }}
                ></i>
              </div>
              <div
                className={`DesignPanel-tools-designRose-content collapse ${
                  menu === "designRose" ? "show" : "null"
                }`}
                id="designRose"
                data-bs-parent="#accordionEx"
              >
                <TextPanel />
              </div>
            </div>
          </div>

          <div className="DesignPanel-tools-packaging">
            <div className="DesignPanel-tools-packaging-container">
              <div
                className="DesignPanel-tools-packaging-title"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Packaging"
                aria-expanded="false"
                aria-controls="Packaging"
                onClick={() => {
                  if (menu !== "packaging") {
                    setMenu("packaging");
                  } else {
                    setMenu("typeRose");
                  }
                }}
              >
                <span style={{
                      fontWeight : menu === "packaging" ? "700" : "400"
                    }}>Packaging</span>
                <i
                  class="bi bi-chevron-up"
                  style={{
                    transform:
                      menu !== "packaging"
                        ? "rotate(0.5turn)"
                        : "rotate(1turn)",
                  }}
                ></i>
              </div>
              <div
                className={`DesignPanel-tools-packaging-content collapse ${
                  menu === "packaging" ? "show" : "null"
                }`}
                id="Packaging"
                data-bs-parent="#accordionEx"
              >
                <Quabtity />
                <Material />
                <Boxes />
              </div>
            </div>
          </div>
        </section>
        
        <section className="DesignPanel-price">
          <Price />
        </section>
      </div>
    </>
  );
}
