export default function Price () {
    return(
        <>
        
            <div className="Price">
                <section className="Price-number">
                    <p>Bill</p>
                    <span>200 $</span>
                </section>
                <section className="Price-add">
                    <button className="btn">Add to card</button>
                </section>
            </div>
        
        </>
    )
}