import HomePage from "./Pages/Home/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/MI.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


function App() {
  return (
    <>
    
      <HomePage />
    
    </>
  );
}

export default App;
