import { useContext, useEffect, useRef, useState } from "react";
import icon2 from "../../../../assets/icon2.png";
import icon3 from "../../../../assets/icon3.png";
import icon4 from "../../../../assets/icon4.png";
import icon5 from "../../../../assets/icon5.png";
import icon6 from "../../../../assets/icon6.png";
import { HomeContext } from "../../Context/HomeContext";

export default function DesignPanelToggler() {
  const { menu, setMenu } = useContext(HomeContext);

  let item1 = "";
  let item2 = "";
  let item3 = "";
  let item4 = "";
  let divider1 = "";
  let divider2 = "";
  let divider3 = "";

  const handleStyle = () => {
    if (menu === "typeRose") {
      item1 = "PanelToggler-item-icon ActiveI";
      item2 = "PanelToggler-item-icon FutureI";
      item3 = "PanelToggler-item-icon FutureI";
      item4 = "PanelToggler-item-icon FutureI";
      divider1 = "FutureD";
      divider2 = "FutureD";
      divider3 = "FutureD";
    } else if (menu === "colorRose") {
      item1 = "PanelToggler-item-icon PastI";
      item2 = "PanelToggler-item-icon ActiveI";
      item3 = "PanelToggler-item-icon FutureI";
      item4 = "PanelToggler-item-icon FutureI";
      divider1 = "PastD";
      divider2 = "FutureD";
      divider3 = "FutureD";
    } else if (menu === "designRose") {
      item1 = "PanelToggler-item-icon PastI";
      item2 = "PanelToggler-item-icon PastI";
      item3 = "PanelToggler-item-icon ActiveI";
      item4 = "PanelToggler-item-icon FutureI";
      divider1 = "PastD";
      divider2 = "PastD";
      divider3 = "FutureD";
    } else if (menu === "packaging") {
      item1 = "PanelToggler-item-icon PastI";
      item2 = "PanelToggler-item-icon PastI";
      item3 = "PanelToggler-item-icon PastI";
      item4 = "PanelToggler-item-icon ActiveI";
      divider1 = "PastD";
      divider2 = "PastD";
      divider3 = "PastD";
    } else {
      item1 = "PanelToggler-item-icon FutureI";
      item2 = "PanelToggler-item-icon FutureI";
      item3 = "PanelToggler-item-icon FutureI";
      item4 = "PanelToggler-item-icon FutureI";
      divider1 = "FutureD";
      divider2 = "FutureD";
      divider3 = "FutureD";
    }
  };
  handleStyle();

  useEffect(() => {
    handleStyle();
  }, [menu]);

  return (
    <>
      <div className="PanelToggler">
        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {menu === "typeRose" && <img src={icon6} alt="pointer" />}
          </div>

          <div
            className={item1}
            onClick={() => {
              setMenu("typeRose");
            }}
          >
            <img src={icon2} alt="Choose Type" width="24px" height="24px" />
          </div>
          <div
            className="PanelToggler-item-text"
            style={{
              fontWeight: menu === "typeRose" ? "900" : "700",
            }}
          >
            Choose Type
          </div>
        </section>

        <div className="PanelToggler-divider">
          <div className={divider1}></div>
        </div>

        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {menu === "colorRose" && <img src={icon6} alt="pointer" />}
          </div>
          <div className={item2} onClick={() => {
              setMenu("colorRose");
            }}>
            <img src={icon3} alt="Choose Color" width="18px" height="18px" />
          </div>
          <div
            className="PanelToggler-item-text"
            style={{
              fontWeight: menu === "colorRose" ? "900" : "700",
            }}
          >
            Choose Color
          </div>
        </section>

        <div className="PanelToggler-divider">
          <div className={divider2}></div>
        </div>

        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {menu === "designRose" && <img src={icon6} alt="pointer" />}
          </div>
          <div className={item3} onClick={() => {
              setMenu("designRose");
            }}>
            <img src={icon4} alt="Design Rose" width="18px" height="18px" />
          </div>
          <div
            className="PanelToggler-item-text"
            style={{
              fontWeight: menu === "designRose" ? "900" : "700",
            }}
          >
            Design Rose
          </div>
        </section>

        <div className="PanelToggler-divider">
          <div className={divider3}></div>
        </div>

        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {menu === "packaging" && <img src={icon6} alt="pointer" />}
          </div>
          <div className={item4} onClick={() => {
              setMenu("packaging");
            }}>
            <img src={icon5} alt="Packaging" width="20px" height="20px" />
          </div>
          <div
            className="PanelToggler-item-text"
            style={{
              fontWeight: menu === "packaging" ? "900" : "700",
            }}
          >
            Packaging
          </div>
        </section>
      </div>
    </>
  );
}
