import { createContext, useState } from "react";

export const TextContext = createContext();

export default function TextContextProvider(props) {
  const colors = [
    "#000000",
    "#393C41",
    "#9C9C9C",
    "#FFFFFF",
    "#384B70",
    "#507687",
    "#FCFAEE",
    "#B8001F",
    "#A1D6B2",
    "#CEDF9F",
    "#F1F3C2",
    "#E8B86D",
    "#A28B55",
    "#86AB89",
    "#CBE2B5",
    "#E7FBE6",
    "#FF204E",
    "#A0153E",
    "#5D0E41",
    "#00224D",
    "#606C5D",
    "#FFF4F4",
    "#F7E6C4",
    "#F1C376",
  ];

  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [font, setFont] = useState("font1");

  const contextValue = { selectedColor, setSelectedColor, colors , font, setFont};
  return (
    <TextContext.Provider value={contextValue}>
      {props.children}
    </TextContext.Provider>
  );
}
