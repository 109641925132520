import { useContext, useState } from "react";
import icon1 from "../../../../../../assets/Icon1.png";
import FontColorDrop from "./FontColorDrop/FontColorDrop";
import FontColorOffcanvas from "./FontColorOff/FontColorOff";
import { TextContext } from "./TextContext/TextContextProvider";
import FontOffcanvas from "./FontOffcanvas/FontOffcanvas";

export default function TextTab() {
  const [text, setText] = useState("");
  const [fontSize, setFontSize] = useState(21);
  const [fontFamily, setFontFamily] = useState("Roboto");
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [selectedOption, setSelectedOption] = useState("front");
  const [textColor, setTextColor] = useState("000000");

  const { selectedColor, setSelectedColor, colors } = useContext(TextContext);

  const handleTextChange = (e) => {
    if (e.target.value.length <= 14) {
      setText(e.target.value);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      <div className="TextTab">
        <textarea
          className="TextTab-textarea"
          style={{
            fontFamily,
            fontSize: `${fontSize}px`,
            fontWeight: bold ? "bold" : "normal",
            fontStyle: italic ? "italic" : "normal",
            textDecoration: underline ? "underline" : "none",
          }}
          maxLength={14}
          value={text}
          onChange={handleTextChange}
          wrap="hard"
        />

        <div className="TextTab-edit">
          <div className="TextTab-edit-r1">
            
            <div className="TextTab-edit-r1-fontFamily">
              <label>Font Type</label>
              <FontOffcanvas />
              <select
                value={fontFamily}
                onChange={(e) => setFontFamily(e.target.value)}
              >
                <option value="Roboto">Roboto</option>
              </select>
            </div>

            <div className="TextTab-edit-r1-fontSize">
              <label htmlFor="">Font Size</label>
              <div className="fontSize-btn">
                <span
                  onClick={() => {
                    setFontSize(fontSize - 2);
                    console.log(fontSize);
                  }}
                >
                  <i class="bi bi-dash"></i>
                </span>
                <img src={icon1} alt="font" />
                <span
                  onClick={() => {
                    setFontSize(fontSize + 2);
                    console.log(fontSize);
                  }}
                >
                  <i class="bi bi-plus"></i>
                </span>
              </div>
            </div>
          </div>

          <div className="TextTab-edit-r2">
            <div className="TextTab-edit-r2-fontColor">
              <label htmlFor="">Font Color</label>
              <FontColorDrop />  {/* desktop */}
              <FontColorOffcanvas /> {/* mobile */}
            </div>
            <button className="TextTab-edit-r2-apply">
              <span>Apply to model</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
