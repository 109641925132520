import { useState } from "react";
import icon10 from '../../../../../assets/icon10.png'

export default function Quabtity() {
  const [quantity, setQuantity] = useState("6-Roses");

  return (
    <>
      <section className="Rose-quantity">
        <h5 className="Rose-quantity-title">Quantity</h5>
        <div className="Rose-quantity-list">
          <div className="Rose-quantity-list-item">
            <div className="Rose-quantity-list-item-image">
              <img src={icon10} alt="" />
            </div>
            <p className="Rose-quantity-list-item-text">6 Roses</p>
          </div>
          <div className="Rose-quantity-list-item">
            <div className="Rose-quantity-list-item-image">
              <img src={icon10} alt="" />
            </div>
            <p className="Rose-quantity-list-item-text">3 Roses</p>
          </div>
          <div className="Rose-quantity-list-item">
            <div className="Rose-quantity-list-item-image">
              <img src={icon10} alt="" />
            </div>
            <p className="Rose-quantity-list-item-text">3 Roses</p>
          </div>
          <div className="Rose-quantity-list-item">
            <div className="Rose-quantity-list-item-image">
              <img src={icon10} alt="" />
            </div>
            <p className="Rose-quantity-list-item-text">3 Roses</p>
          </div>
          <div className="Rose-quantity-list-item">
            <div className="Rose-quantity-list-item-image">
              <img src={icon10} alt="" />
            </div>
            <p className="Rose-quantity-list-item-text">3 Roses</p>
          </div>
          <div className="Rose-quantity-list-item">
            <div className="Rose-quantity-list-item-image">
              <img src={icon10} alt="" />
            </div>
            <p className="Rose-quantity-list-item-text">3 Roses</p>
          </div>
        </div>
      </section>
    </>
  );
}
