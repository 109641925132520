export default function Boxes() {
  return (
    <>
      <div className="Boxes">
        <h5 className="Rose-Boxes-title">Boxes</h5>
        <div className="Rose-Boxes-list">
            <section className="Rose-Boxes-list-item"></section>
            <section className="Rose-Boxes-list-item"></section>
            <section className="Rose-Boxes-list-item"></section>
            <section className="Rose-Boxes-list-item"></section>
            <section className="Rose-Boxes-list-item"></section>
            <section className="Rose-Boxes-list-item"></section>
            <section className="Rose-Boxes-list-item"></section>
            <section className="Rose-Boxes-list-item"></section>
        </div>
      </div>
    </>
  );
}
