import { createContext, useState } from "react";

export const HomeContext = createContext();

export default function HomeContextProvider(props) {
  const [menu, setMenu] = useState("typeRose");

  const contextValue = { menu, setMenu };
  return (
    <HomeContext.Provider value={contextValue}>
      {props.children}
    </HomeContext.Provider>
  );
}
